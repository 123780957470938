import React from 'react'
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import HomeMenu from '../Layout/Menu/Home';
import "./index.scss"
import { checkUser } from '../../services/userService';
import { v4 as uuidv4 } from 'uuid';
import { getStartExamByClassGroupExamSlug } from '../../services/ieltsService';
import toastifyClient from '../../untils/toastifyClient';
import { isDesktop } from "react-device-detect";

const ExamBySlug = () => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();
  const groupExamSlug = searchParams.get('slug') 

  const onStartExam = async (data) => {
      // console.log(data)
      try {
          const result = await checkUser()
      } catch (error) {
         alert('Bạn cần đăng nhập trước khi làm bài Test')
         return navigate(`/auth/login?r=/exam-by-slug?slug=${groupExamSlug}`)
      }

      try {
          const result = await getStartExamByClassGroupExamSlug(groupExamSlug)
          if(!result?.isSuccess) return toastifyClient.error(result?.errors[0]?.message)
          let skill,subSkill=""
          switch (result.data.testType) {
              case "Short":
                  skill="/shorttest"
                  break;
              case "Full":
                  skill="/fulltest"                    
                  break;

              case "AISpeakingBasic":
                  skill="/speakingai"
                  subSkill="basic"
                  break;
              case "AISpeakingAdvanced":
                  skill="/speakingai"
                  subSkill="advance"                    
                  break;
              default:
                  break;
          }
          if(!isDesktop && (skill=="/shorttest" || skill=="/fulltest")) return alert("kỹ nằng ShortTest, FullTest hiện đang không hỗ trợ trên Mobile")

          localStorage.setItem("skill",skill)
          localStorage.setItem("subSkill",subSkill)
          localStorage.setItem('exam',JSON.stringify(result.data))
          return navigate(skill)
      
      } catch (error) {
          alert("API Error")
      }
  }
  return (
    <>
      <div className='container-fluid homepage' id="homepage">
        <HomeMenu />
        <header id='header'>
          <div id="intro" className="bg-image">
            <div className="box-input-code">
              <button className="btn btn-primary" onClick={onStartExam}>Bắt đầu làm bài</button>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}

export default ExamBySlug
